/**
 * ICI ON EST SUR UN POINT D'ENTRÉE (ENTRY POINT) DE WEBPACK, CE QUI SIGNIFIE
 * QUE TOUT CE QUI VA ÊTRE CHARGÉ ICI SERA COMPILÉ DANS UN FICHIER LEAN.JS|CSS
 * 
 * Outre ce qui est commun aux deux entry points lean et fat, j'importe ici les
 * controllers Stimulus les plus communs, servant à tous
 */

import './common';
import './stimulus_lean';
