/**
 * Ici on importe tout ce qui sera commun aux entry points lean et fat
 * 
 * À propos des imports ici :
 *
 * import './gnagnagna';        <-- le ./ (ou ../) indique à Webpack que la
 *                                  ressource à charger est un fichier à nous,
 *                                  relatif à app.js (note d'ailleurs que l'on
 *                                  n'écrit pas l'extension .js sur le fichier,
 *                                  c'est optionnel).
 *
 * import $ from 'jquery';      <-- L'absence de . ou .. en début de fichier dit
 *                                  à Webpack qu'on veut importer un module
 *                                  tiers. Ce package doit bien entendu être
 *                                  placé dans node_modules, son point d'entrée
 *                                  est un fichier package.json à la racine du
 *                                  module (clé 'main'). Pour installer ce
 *                                  module : yarn add <module> --dev
 *                                  (liste des packages disponible sur la page
 *                                  https://yarnpkg.com)
 */

// Tout le CSS du site sera mis dans un unique fichier de sortie
import './styles/all.scss';

// La librairie Bootstrap
import 'bootstrap';
